.ModalVideo {
	position: fixed;
	z-index: 10000000000000000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	transition: all 0.5s ease;
	background: rgba(0, 0, 0, 0.85);
	&__containers {
		width: 100%;
		height: 100%;
		position: relative;
		padding: 45px;
	}
	&__close {
		position: absolute;
		top: 30px;
		right: 45px;
		width: 40px;
		height: 40px;
		// cursor: pointer;
		cursor: var(--cursor);
		z-index: 10000000000000001;
	}
	&__video {
		width: 95%;
		height: 75%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 650px) {
	.ModalVideo__close {
		position: absolute;
		top: 30px;
		right: 30px;
		width: 40px;
		height: 40px;
		// cursor: pointer;
		cursor: var(--cursor);
		z-index: 10000000000000000;
	}
}
