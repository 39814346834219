.form {
	width: 100%;
	height: 100vh;
	background: #121212;
	position: relative;
	overflow: hidden;
	z-index: 2;
	&__loading {
		display: block;
		&-start {
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			background: #fff;
			z-index: 100000000;
		}
		&-text {
			position: fixed;
			top: 50%;
			left: 50%;
			z-index: 1000000005;
			text-align: center;
			word-wrap: break-word;
			width: calc(1ch + 10px);
			font-family: TTNorms;
			font-style: normal;
			font-weight: 600;
			font-size: 11px;
			line-height: 22px;
		}
		&-state {
			width: 100%;
			// height: 100vh;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			background: #000;
			z-index: 1000000001;
		}
	}
	&__wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 1440px;
		padding: 0 0 0 110px;
		margin: 0 auto;
		&-banner {
			width: 90%;
			height: 100%;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.4);
				width: 100%;
				height: 100%;
				z-index: 2;
			}
			& img {
				width: 100%;
				position: relative;
				z-index: 1;
				height: 100%;
			}
		}
		&-info {
			width: 40%;
			height: 100%;
			padding: 340px 0 0 0;
			position: relative;
		}
		&-titles {
			position: absolute;
			z-index: 4;
			top: 150px;
			left: -75px;
			& p {
				font-family: Ekors_Cyrilic_fmch_art_Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 70px;
				line-height: 67px;
				text-transform: uppercase;
				color: #ffffff;
				&:nth-child(2) {
					margin: 0 0 0 230px;
				}
			}
		}
	}
	& iframe {
		width: 370px;
		height: 368px;
		border: none;
	}
}

.iframe__block {
	width: 370px;
	height: 380px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	position: relative;
	& p {
		font-family: TTNorms;
		font-style: normal;
		font-weight: 400;
		font-size: 27px;
		line-height: 31px;
		color: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}
	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}
}

@media (max-width: 1200px) {
	.form__wrapper-banner {
		display: none;
	}
	.form__wrapper-titles {
		display: none;
	}
	.form__wrapper-info {
		width: 100%;
		height: 100%;
		padding: 340px 0 0 0;
		position: relative;
		align-items: center;
		justify-content: center;
		display: flex;
	}
	.form__wrapper {
		padding: 0;
	}
	.form__wrapper-info {
		padding: 0;
	}
	.form {
		height: calc(var(--vh, 1vh) * 100) !important;
	}
}
