:root {
	--vh: 1vh;
}

.home {
	width: 100%;
	// height: 100vh;
	// height: calc(var(--vh, 1vh) * 100);
	height: 100%;
	overflow: hidden;
	transition: all 0.8s ease;
	& video {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
	}
	& .swiper-button-prev {
		position: absolute;
		left: 38%;
		bottom: 37px;
		right: auto;
		top: auto;
		transition: all 0.5s ease;
		transform: translate(-38%, 0);
		&::after {
			content: url('../../icons/interface/nav__prev.svg');
		}
	}
	& .swiper-button-next {
		position: absolute;
		transition: all 0.5s ease;
		right: 38%;
		bottom: 37px;
		left: auto;
		top: auto;
		transform: translate(38%, 0);
		&::after {
			content: url('../../icons/interface/nav__next.svg');
		}
	}
}

.custom-class {
	width: 100%;
	height: 380px;
}

.pagination-bottom {
	font-size: 0px;
}

.pagination-top {
	position: absolute;
	top: 10px;
	right: 10px;
	margin: 0;
}

.pagination-top .swiper-pagination-bullet {
	width: auto;
	border-radius: 0px;
	height: auto;
	background-color: #fff;
	padding: 5px;
	color: #000;
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
}

.pagination-top .swiper-pagination-bullet-active {
	color: #fff;
	background: #007aff;
}

.slider__one-open {
	position: absolute;
	z-index: 5;
	bottom: 41px;
	right: 50px;
	width: 164px;
	padding: 9px 0;
	background: rgba(0, 0, 0, 0.3);
	border: 2px solid rgba(255, 255, 255, 0.6);
	border-radius: 16.5px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-family: TTNorms;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	&-hide {
		position: absolute;
		top: 15%;
		background: transparent;
		right: 0;
		height: 85%;
		width: 35%;
		// cursor: pointer;
		cursor: var(--cursor);
		z-index: 5;
	}
}

.slider__two {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&-video {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	&-title {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translate(-50%, 0%);
		font-family: NextArt;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.3em;
		color: #fffef4;
		// opacity: 1 !important;
		z-index: 10;
	}
	&-open {
		position: absolute;
		z-index: 5;
		bottom: 41px;
		right: 50px;
		width: 164px;
		padding: 9px 0;
		background: rgba(0, 0, 0, 0.3);
		border: 2px solid rgba(255, 255, 255, 0.6);
		border-radius: 16.5px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		text-transform: uppercase;
		color: #ffffff;
		&-hide {
			position: absolute;
			top: 15%;
			background: transparent;
			right: 0;
			height: 85%;
			width: 35%;
			// cursor: pointer;
			cursor: var(--cursor);
			z-index: 5;
		}
	}
}

.slider__three {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&-title {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translate(-50%, 0);
		font-family: NextArt;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.3em;
		color: #fffef4;
		z-index: 10;
		// transition: all 0.5s ease;
	}
	&-wrapper {
		width: 100%;
		// height: 100vh;
		height: 100%;
		& video {
			width: 100vh;
			// height: 100vh;
			height: 100%;
		}
	}
	&-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 5;
		background: transparent;
		height: 100%;
		width: 100%;
		& img {
			transition: all 0.5s ease;
			width: max-content;
			height: max-content;
			// cursor: pointer !important;
			cursor: var(--cursor);
		}
	}
	&-mute {
		position: absolute;
		bottom: 41px;
		right: 50px;
		z-index: 12;
		display: flex;
		align-items: center;
		justify-content: center;
		// cursor: pointer;
		cursor: var(--cursor);
		width: 36px;
		height: 36px;
		background: rgba(0, 0, 0, 0.3);
		border: 2px solid rgba(255, 255, 255, 0.6);
		border-radius: 50%;
		& img {
			width: 90%;
			height: 90%;
			&:nth-child(2) {
				display: none;
			}
		}
		&-a {
			& img:nth-child(1) {
				display: none;
			}
			& img:nth-child(2) {
				display: flex;
			}
		}
	}
}

.slider__four {
	width: 100%;
	height: 100%;
	position: relative;
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&-title {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translate(-50%, 0);
		font-family: NextArt;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.3em;
		color: #fffef4;
		z-index: 10;
		// transition: all 0.5s ease;
	}
}

.slider__five {
	width: 100%;
	height: 100%;
	position: relative;
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&-title {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translate(-50%, 0);
		font-family: NextArt;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.3em;
		color: #fffef4;
		z-index: 10;
		// transition: all 0.5s ease;
	}
	&-contact {
		position: absolute;
		bottom: 20%;
		left: 155px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 3px;
	}
	&-link {
		// cursor: pointer;
		cursor: var(--cursor);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 2px;
		& a {
			font-family: TTNorms;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 26px;
			color: #ffffff;
			transition: all 0.5s ease;
			// cursor: pointer;
			cursor: var(--cursor);
			&:hover {
				color: #999999;
				transition: all 0.5s ease;
			}
		}
	}
	&-address {
		margin: 75px 0 0 0;
		& a {
			font-family: TTNorms;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 26px;
			color: #ffffff;
		}
	}
}

.arrowTopOne,
.arrowTopTwo {
	position: fixed;
	bottom: 20px;
	right: 60px;
	width: 55px;
	height: 55px;
	background: transparent;
	z-index: 13;
	// cursor: pointer;
	cursor: var(--cursor);
	transition: all 0.5s ease;
}

@media (max-width: 630px) {
	.slider__two-title {
		left: 32px;
	}
	.slider__three-title {
		left: 32px;
	}
	.slider__four-title {
		left: 32px !important;
	}
	.slider__five-title {
		display: none;
	}
	.slider__three-mute {
		right: 32px;
	}
	.interface__link {
		left: 32px !important;
		font-size: 10px !important;
		line-height: 12px !important;
	}
}

@media (max-width: 600px) {
	// .home {
	// 	& .swiper-wrapper {
	// 		height: 100%;
	// 	}
	// 	& .swiper-slide {
	// 		height: 100%;
	// 	}
	// }
	.slider__two-open-hide,
	.slider__one-open-hide {
		display: none !important;
	}
}

@media (max-width: 1430px) {
	.home .swiper-button-prev {
		position: absolute;
		left: 35%;
		bottom: 37px;
		right: auto;
		top: auto;
		transition: all 0.5s ease;
		transform: translate(-38%, 0);
	}
	.home .swiper-button-next {
		position: absolute;
		right: 34%;
		bottom: 37px;
		left: auto;
		top: auto;
		transition: all 0.5s ease;
		transform: translate(-38%, 0);
	}
}

@media (max-width: 1150px) {
	.slider__five-contact {
		position: absolute;
		bottom: 20%;
		left: 95px !important;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.slider__one-types h3,
	.slider__two-title,
	.slider__three-title,
	.slider__four-title,
	.slider__five-title {
		font-size: 14px;
	}
}

@media (max-width: 1000px) {
	.home .swiper-button-next {
		position: absolute;
		right: 32px;
		top: 50%;
		width: 50px;
		height: 300px;
		left: auto;
		bottom: auto;
		transition: all 0.5s ease;
		transform: translate(0%, -50%);
		&::after {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0%, -50%);
		}
	}
	.home .swiper-button-prev {
		position: absolute;
		left: 32px;
		top: 50%;
		width: 50px;
		height: 300px;
		right: auto;
		bottom: auto;
		transition: all 0.5s ease;
		transform: translate(0%, -50%);
		&::after {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0%, -50%);
		}
	}
	.slider__one-types {
		display: none;
	}
	.slider__one-title {
		position: absolute;
		bottom: 170px;
		left: 32px;
		transform: none;
		text-align: left;
		z-index: 10;
		transition: all 0.5s ease;
	}
	.slider__one .swiper-pagination {
		position: absolute;
		top: auto !important;
		right: auto !important;
		bottom: 125px !important;
		width: max-content !important;
		z-index: 5 !important;
		left: 32px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: none !important;
	}
	.interface__link {
		position: absolute;
		bottom: 41px;
		left: 32px;
		padding: 7px 26px !important;
		background: rgba(0, 0, 0, 0.3);
		border: 2px solid rgba(255, 255, 255, 0.6);
		border-radius: 16.5px;
		cursor: var(--cursor);
		text-align: center;
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		text-transform: uppercase;
		color: #ffffff;
		z-index: 12;
	}
	.slider__one-open {
		position: absolute;
		z-index: 5;
		bottom: 41px;
		right: 32px;
		width: unset;
		padding: 7px 36px;
		background: rgba(0, 0, 0, 0.3);
		border: 2px solid rgba(255, 255, 255, 0.6);
		border-radius: 16.5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
		text-transform: uppercase;
		color: #ffffff;
	}
	.slider__two-open {
		position: absolute;
		z-index: 5;
		bottom: 41px;
		right: 50px;
		width: unset;
		padding: 7px 36px !important;
		background: rgba(0, 0, 0, 0.3);
		border: 2px solid rgba(255, 255, 255, 0.6);
		border-radius: 16.5px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
		text-transform: uppercase;
		color: #ffffff;
	}
	.slider__two-title,
	.slider__three-title,
	.slider__four-title {
		position: absolute;
		bottom: 105px;
		left: 32px;
		transform: none;
		font-family: NextArt;
		font-style: normal;
		font-weight: 700;
		font-size: 23px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.3em;
		color: #fffef4;
		z-index: 10;
	}
	.slider__five-title {
		display: none;
	}
	.slider__three-btn {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -48%);
		z-index: 5;
		background: transparent;
		height: 100%;
		width: 100%;
	}
	.slider__four-title {
		text-align: left !important;
	}
}
