//! Font

@font-face {
	font-family: Ekors_Cyrilic_fmch_art_Regular;
	font-display: swap;
	src: url('../public/fonts/Ekors_Cyrilic_fmch_art_Regular.woff') format('woff'),
		url('../public/fonts/Ekors_Cyrilic_fmch_art_Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: NextArt;
	font-display: swap;
	src: url('../public/fonts/NextArt-Bold.woff') format('woff'),
		url('../public/fonts/NextArt-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: EkorsNonCommercial;
	font-display: swap;
	src: url('../public/fonts/EkorsNonCommercial.woff') format('woff'),
		url('../public/fonts/EkorsNonCommercial.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: NextArt;
	font-display: swap;
	src: url('../public/fonts/NextArt-Heavy.woff') format('woff'),
		url('../public/fonts/NextArt-Heavy.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: NextArt;
	font-display: swap;
	src: url('../public/fonts/NextArt-Regular.woff') format('woff'),
		url('../public/fonts/NextArt-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: NextArt;
	font-display: swap;
	src: url('../public/fonts/NextArt-Light.woff') format('woff'),
		url('../public/fonts/NextArt-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: NextArt;
	font-display: swap;
	src: url('../public/fonts/NextArt-SemiBold.woff') format('woff'),
		url('../public/fonts/NextArt-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-BlackItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-BlackItalic.woff2') format('woff2');
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-BoldItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-BoldItalic.woff2') format('woff2');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Bold.woff') format('woff'),
		url('../public/fonts/TTNorms-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Black.woff') format('woff'),
		url('../public/fonts/TTNorms-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-ExtraBold.woff') format('woff'),
		url('../public/fonts/TTNorms-ExtraBold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-ExtraBoldItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-ExtraBoldItalic.woff2') format('woff2');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-ExtraLightItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-ExtraLightItalic.woff2') format('woff2');
	font-weight: 200;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-ExtraLight.woff') format('woff'),
		url('../public/fonts/TTNorms-ExtraLight.woff2') format('woff2');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-HeavyItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-HeavyItalic.woff2') format('woff2');
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Italic.woff') format('woff'),
		url('../public/fonts/TTNorms-Italic.woff2') format('woff2');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-LightItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-LightItalic.woff2') format('woff2');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Light.woff') format('woff'),
		url('../public/fonts/TTNorms-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Heavy.woff') format('woff'),
		url('../public/fonts/TTNorms-Heavy.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Medium.woff') format('woff'),
		url('../public/fonts/TTNorms-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-MediumItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-MediumItalic.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Thin.woff') format('woff'),
		url('../public/fonts/TTNorms-Thin.woff2') format('woff2');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-Regular.woff') format('woff'),
		url('../public/fonts/TTNorms-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: TTNorms;
	font-display: swap;
	src: url('../public/fonts/TTNorms-ThinItalic.woff') format('woff'),
		url('../public/fonts/TTNorms-ThinItalic.woff2') format('woff2');
	font-weight: 100;
	font-style: italic;
}

//! Var

$swiper-theme-color: transparent !important;

//! Base

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	user-select: none;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	background: #000;
	height: 100%;
	&::-webkit-scrollbar {
		width: 0;
	}
}

html,
html #root,
html body,
body .home {
	height: 100%;
	overflow: hidden;
}

html[data-cursor='all'] {
	--cursor: none;
}

html[data-cursor='safari'] {
	--cursor: pointer;
}

a {
	text-decoration: none;
	cursor: var(--cursor);
}

ul {
	list-style: none;
}

input {
	padding: 0;
	margin: 0;
	border: 0;
	border: none;
}

button {
	padding: 0;
	border: none;
	cursor: var(--cursor);
	// cursor: pointer;
}

ol,
ul {
	padding-left: 0;
}

dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 0;
}

p {
	margin-top: 0;
	margin-bottom: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

.containers {
	max-width: 1440px;
	padding: 0 50px;
	margin: 0 auto;
	width: 100%;
}

.opacity {
	opacity: 0;
	cursor: not-allowed;
}

.opacity-t {
	opacity: 0;
	transition: all 0.5s ease;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: none !important;
}

.swiper-button-next,
.swiper-button-prev {
	cursor: var(--cursor) !important;
}

//! Add

@media (max-width: 900px) {
	.cursor,
	.cursor2 {
		display: none !important;
	}
}

.slider {
	width: 100%;
	// height: 100vh;
	height: 100%;
	height: 100%;
	background: black;
	&__wrappers {
		width: 100%;
		height: 100%;
		position: relative;
		&-project {
			position: absolute;
			left: 50px;
			bottom: 41px;
			z-index: 10;
			border: 1px solid #999999;
			border-radius: 16.5px;
			background: transparent;
			display: flex;
			align-items: center;
			justify-content: center;
			// cursor: pointer;
			cursor: var(--cursor);
			padding: 10px 33px;
			& a {
				font-family: TTNorms;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				text-transform: uppercase;
				color: #ffffff;
			}
		}
		&-detailed {
			position: absolute;
			right: 50px;
			bottom: 41px;
			z-index: 10;
			border: 1px solid #999999;
			border-radius: 16.5px;
			background: transparent;
			display: flex;
			align-items: center;
			justify-content: center;
			// cursor: pointer;
			cursor: var(--cursor);
			padding: 10px 44px;
			& a {
				font-family: TTNorms;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				text-transform: uppercase;
				color: #ffffff;
			}
		}
		&-slider {
			width: 100%;
			height: 100%;
			z-index: 4;
			& .swiper-pagination {
				bottom: 46px;
				z-index: 1;
			}
			& .swiper-pagination-bullet {
				height: 8px;
				border: 1px solid #8a8a8a;
				border-radius: 9px;
				background: transparent;
				opacity: 1;
				&:nth-child(even) {
					width: 41px;
				}
				&:nth-child(odd) {
					width: 18px;
				}
				&-active {
					background: #c4c4c4;
				}
			}
			& .swiper {
				width: 100%;
				height: 100%;
			}
			&-background {
				width: 100%;
				height: 100%;
				& img {
					width: 100%;
					height: 100%;
				}
			}
			&-items {
				position: relative;
				height: 100%;
			}
			&-title {
				position: absolute;
				z-index: 5;
				bottom: 170px;
				left: 126px;
				font-family: TTNorms;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 30px;
				letter-spacing: 0.3em;
				color: #fffef4;
			}
		}
		&-text {
			width: 100%;
			// height: 100vh;
			height: 100%;
			position: relative;
		}
		&-text-item {
			color: #fff;
			letter-spacing: 12px;
			font-weight: 600;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, 50%);
			text-align: center;
			word-wrap: break-word;
			width: calc(1ch + 10px);
			font-family: TTNorms;
			font-style: normal;
			font-weight: 600;
			font-size: 11px;
			line-height: 22px;
			z-index: 1000;
		}
		&-preloader {
			z-index: 100;
			position: absolute;
			width: 100%;
			// height: 100vh;
			height: 100%;
			background: black;
			top: 0;
			left: 0;
		}
		&-nav {
			position: absolute;
			top: 32px;
			left: 50%;
			transform: translate(-50%, 0%);
			z-index: 50;
			height: 32px;
			padding: 0 50px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			&-logo {
				font-family: TTNorms;
				font-style: normal;
				font-weight: bold;
				font-size: 15px;
				line-height: 18px;
				text-transform: uppercase;
				color: #ffffff;
			}
			&-list {
				display: flex;
				margin: 0 0 0 35%;
			}
			&-item {
				font-family: TTNorms;
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 18px;
				color: #727272;
				text-transform: uppercase;
				transition: all 0.5s ease;
				&:hover {
					color: #fff;
					transition: all 0.5s ease;
				}
				&-active {
					color: #fff;
				}
			}
			&-menu {
				width: 32px;
				height: 32px;
				// cursor: pointer;
				cursor: var(--cursor);
			}
		}
	}
}

.loader__start {
	width: 100%;
	// height: 100vh;
	height: 100%;
	background: #000;
	position: absolute;
	left: -100%;
	top: 0;
	z-index: 10000000000;
}
.loader__end {
	width: 100%;
	// height: 100vh;
	height: 100%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: -100%;
	top: 0;
	z-index: 10000000000;
}

.d_loader__start {
	width: 100%;
	// height: 100vh;
	height: 100%;
	background: #000;
	position: absolute;
	right: 0%;
	top: 0;
	z-index: 10000000000;
}
.d_loader__end {
	width: 100%;
	// height: 100vh;
	height: 100%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0%;
	top: 0;
	z-index: 100000000000;
}

// .cursor {
// 	position: fixed;
// 	height: 8px;
// 	width: 8px;
// 	border-radius: 50%;
// 	transform: translateX(-50%) translateY(-50%);
// 	// mix-blend-mode: difference !important;
// 	/*   z-index: 9999; */
// }

// .cursor:nth-child(1) {
// 	background-color: red;
// 	z-index: 1000;
// }

// .cursor:nth-child(2) {
// 	background-color: gray;
// 	width: 22px;
// 	height: 22px;
// 	transition: width 400ms ease, height 400ms ease;
// }
// .cursor {
// 	pointer-events: none;
// }

/* .home {
	width: 100%;
	// height: 100vh;
	height: 100%;
	overflow: hidden;
	transition: all 0.8s ease;
	& video {
		width: 100vw;
		// height: 100vh;
		height: 100%;
		object-fit: cover;
	}
} */

.display-n {
	display: none !important;
}

.homeSliders {
	width: 100%;
	height: 100vh;
	height: 100%;
	height: 100%;
	position: relative;
	& .swiper-pagination {
		position: absolute;
		top: 32px;
		right: 14%;
		left: auto !important;
		bottom: auto !important;
		width: max-content !important;
		height: 18px !important;
	}
	& .swiper-pagination-bullet {
		width: max-content;
		background: transparent !important;
		font-family: TTNorms;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 18px;
		height: 18px;
		color: gray !important;
		opacity: 1 !important;
		&-active {
			color: #fff !important;
		}
	}
}
