.detailOne {
	width: 100%;
	height: 100%;
	padding: 110px 0 0 0;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	background: rgba(18, 18, 18, 0.6);
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
		width: 0;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 1px 1px transparent;
		border: solid 12px transparent;
	}
	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 1px 1px transparent;
		border: solid 10px transparent;
	}
	& .details__footer {
		position: relative;
	}
	// &__wr {
	// 	width: 100%;
	// 	height: 100%;
	// 	overflow-x: hidden;
	// 	overflow-y: scroll;
	// }
	&__block {
		position: relative;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 50px;
		&-title {
			font-family: EkorsNonCommercial;
			font-style: normal;
			font-weight: bold;
			font-size: 160px;
			line-height: 189px;
			letter-spacing: 0.1em;
			-webkit-text-stroke: 0.5px #f0f0f0;
			position: absolute;
			top: 290px;
			left: 50px;
			z-index: 10;
			visibility: hidden;
		}
		&-titles {
			display: none;
		}
		&-decor {
			position: absolute;
			top: 300px;
			left: 50%;
			transform: translate(-50%, 0%);
			width: 185px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			gap: 45px;
			z-index: 1;
			display: none;
			&-i {
				&:nth-child(even) {
					width: 100%;
					height: 460px;
					background: #212121;
					border-radius: 90px;
				}
				&:nth-child(odd) {
					border-radius: 50%;
					background: #323232;
					height: 185px;
					width: 100%;
				}
			}
		}
		&-list {
			display: flex;
			margin: 0 0 0 auto;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 665px;
			position: relative;
			z-index: 10;
		}
		&-items {
			width: unset;
			position: relative;
			margin: 0 0 36px 0;
			&:last-child {
				margin: 0;
			}
			& img {
				z-index: 1;
				object-fit: cover;
				transition: all 0.5s ease;
				width: 650px;
				height: 273px;
			}
			& video {
				transition: all 0.5s ease;
				opacity: 0;
				width: 650px;
				height: 273px;
				position: absolute;
				left: 0px;
				z-index: 1;
				object-fit: cover;
			}
			&-info {
				z-index: 10;
				margin: 20px 0 0 0;
				display: flex;
				flex-direction: column;
				position: relative;
				& p {
					width: max-content;
					font-family: NextArt;
					font-style: normal;
					font-weight: normal;
					font-size: 11px;
					line-height: 27px;
					letter-spacing: 0.3em;
					color: #acacac;
				}
				& h3 {
					width: 600px;
					font-family: NextArt;
					font-style: normal;
					font-weight: normal;
					font-size: 24px;
					line-height: 27px;
					letter-spacing: 0.3em;
					color: #fffef4;
				}
			}
		}
		&-left {
			padding: 190px 50px 190px 50px;
			& .detailOne__block-list {
				margin: 0 auto 0 0;
			}
			& .detailOne__block-title {
				position: absolute;
				right: 85px;
				left: auto;
				width: max-content;
				top: 370px;
				& > div {
					width: 90%;
					& img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 1100px) {
	.detailOne__block-title {
		display: none;
		transform: none !important;
		height: 0vh !important;
	}
	.detailOne__block-titles {
		display: block;
		position: relative;
		z-index: 10;
		padding: 0 0 125px 0;
	}
	.detailOne__block {
		&:nth-child(even) {
			.detailOne__block-titles {
				margin: 0 auto 0 0;
				width: 600px;
				& img {
					width: 100%;
				}
			}
		}
		&:nth-child(odd) {
			.detailOne__block-titles {
				margin: 0 0 0 auto;
				width: 600px;
				& img {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 880px) {
	.detailOne__footer-contact {
		display: none;
	}
}

@media (max-width: 800px) {
	.detailOne__block-list {
		margin: 0 auto;
		width: 100%;
	}
	.detailOne__block-items-info h3 {
		width: max-content;
	}
	.detailOne__block-items {
		width: 100%;
	}
	.detailOne__block-titles {
		display: block;
		position: relative;
		z-index: 10;
		padding: 110px 0 55px 0;
	}
	.detailOne__block-items img {
		height: 150px;
	}
	.detailOne__block {
		&:nth-child(even) {
			.detailOne__block-titles {
				margin: 0 auto 0 0;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				& img {
					width: max-content;
					height: 38px;
				}
			}
		}
		&:nth-child(odd) {
			.detailOne__block-titles {
				margin: 0 0 0 auto;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				& img {
					width: max-content;
					height: 38px;
				}
			}
		}
	}
}

@media (max-width: 650px) {
	.detailOne__block {
		padding: 0 32px;
	}
	.detailOne__block-items-info h3 {
		font-size: 16px;
	}
	.arrowTop {
		right: 32px !important;
		width: 40px !important;
	}
	.interface__close-d-one {
		right: 30px !important;
	}
	.detailOne__block-items video {
		display: none !important;
	}
	.detailOne__block-items img {
		opacity: 1 !important;
	}
}

@media (max-width: 500px) {
	.detailOne__footer-w {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: flex-start;
	}
	.detailOne__footer-year {
		margin: 15px 0 0 0;
	}
	.interface__close-d-one p {
		display: none;
	}
}

@media (max-width: 1800px) {
	.detailOne__footer-w {
		width: 60%;
		max-width: 1440px;
		padding: 0 50px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media (max-width: 1400px) {
	.detailOne__footer-w {
		width: 70%;
		max-width: 1440px;
		padding: 0 50px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media (max-width: 1200px) {
	.detailOne__footer-w {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 30px;
		justify-items: center;
	}
}

@media (max-width: 880px) {
	.detailOne__footer-year {
		grid-area: 2 / 1;
	}
	.detailOne__footer-w {
		width: 100%;
		gap: 0;
	}
	.detailOne__footer {
		width: 100%;
		padding: 44px 0 85px 0;
		background: #1a1a1a;
		margin: 150px 0 0 0;
		position: relative;
		z-index: 4;
	}
}

@media (max-width: 750px) {
	.detailOne__block-items img {
		width: 100%;
	}
	.detailOne__block-items video {
		width: 100%;
	}
}

@media (max-width: 450px) {
	.detailOne__block:nth-child(2n) .detailOne__block-titles img {
		height: 32px;
		width: max-content;
	}
}
