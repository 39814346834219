.detailTwo {
	width: 100%;
	background: #121212;
	position: relative;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 600px 0 0 0;
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
		width: 0;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 1px 1px transparent;
		border: solid 12px transparent;
	}
	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 1px 1px transparent;
		border: solid 10px transparent;
	}
	& .details__footer {
		position: relative;
	}
	&__line {
		position: absolute !important;
		top: 140px;
		z-index: 3;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		&-p {
			margin: auto 75px;
		}
		& p {
			font-family: EkorsNonCommercial;
			font-style: normal;
			font-weight: bold;
			font-size: 500px;
			line-height: 590px;
			color: #f6f6f4;
			margin: 0 !important;
			text-transform: uppercase;
		}
		& .overlay {
			&::after {
				content: none;
			}
			&::before {
				content: none;
			}
		}
		& .marquee {
			font-family: EkorsNonCommercial;
			font-style: normal;
			font-weight: bold;
			font-size: 500px;
			line-height: 590px;
			color: #f6f6f4;
			margin: 0 !important;
		}
	}
	&__container {
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 60px;
		position: relative;
	}
	&__decor {
		position: absolute;
		width: 185px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		gap: 45px;
		z-index: 1;
		left: 50%;
		transform: translate(-50%, 0%);
		top: 790px;
		display: none;
		&-i {
			width: 100%;
			&:nth-child(odd) {
				height: 185px;
				border-radius: 50%;
				background: #323232;
			}
			&:nth-child(even) {
				height: 460px;
				border-radius: 90px;
				background: #212121;
			}
		}
	}
	&__description {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		z-index: 5;
		position: relative;
		transition: all 0.5s ease;
		padding: 260px 0 55px 0;
		&-t {
			font-family: Ekors_Cyrilic_fmch_art_Regular;
			font-style: normal;
			font-weight: 400;
			font-size: 50px;
			line-height: 77px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: #ffffff;
			position: relative;
			z-index: 3;
		}
		&-d {
			margin: 21px 0 0 0;
			font-family: TTNorms;
			font-style: normal;
			font-weight: 700;
			font-size: 50px;
			line-height: 77px;
			text-transform: uppercase;
			color: #ffffff;
			position: relative;
			z-index: 3;
		}
		&-b {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin: 170px 0 0 0;
			width: 735px;
			& h3 {
				font-family: NextArt;
				font-style: normal;
				font-weight: 700;
				font-size: 26px;
				line-height: 30px;
				color: #ffffff;
			}
			& p {
				font-family: NextArt;
				font-style: normal;
				font-weight: 500;
				font-size: 26px;
				line-height: 45px;
				color: #ffffff;
				margin: 8px 0 0 0;
			}
		}
		&-g {
			width: 550px;
			height: 875px;
			border-radius: 276px;
			position: absolute;
			top: 30px;
			right: 0;
			z-index: 1;
		}
		&-glish {
			width: 100%;
			height: 100%;
			border-radius: 276px;
			& canvas {
				border-radius: 276px;
			}
		}
	}
	&__redirect {
		display: none;
		&-start {
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			left: -100%;
			background: #fff;
			z-index: 100000000;
		}
		&-state {
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			left: -100%;
			background: #000;
			z-index: 1000000001;
		}
	}
	&__partner {
		padding: 230px 0 90px 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		position: relative;
		z-index: 5;
		&-t {
			font-family: Ekors_Cyrilic_fmch_art_Regular;
			font-style: normal;
			font-weight: 400;
			font-size: 60px;
			line-height: 77px;
			letter-spacing: 0.09em;
			text-transform: uppercase;
			color: #ffffff;
		}
		&-l {
			margin: 60px 0 0 0;
			// display: grid;
			// grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
			// gap: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
		}
		&-i {
			width: 110px;
			height: 110px;
			border: 2px solid #727272;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #121212;
		}
	}
	&__tab {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 5;
		margin: 120px auto 55px auto;
		max-width: 1440px;
		padding: 90px 60px 50px 60px;
	}
	&__tabs {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 70px 0 100px 0;
		&-btns {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
			gap: 50px 30px;
			width: 100%;
			position: relative;
			z-index: 5;
		}
		&-header {
			width: 100%;
			height: 80px;
			background: #121212;
			border: 2px solid #595959;
			border-radius: 51px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 15;
			font-family: TTNorms;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 26px;
			text-align: center;
			text-transform: uppercase;
			color: #ffffff;
			display: none;
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 39px;
				width: 24px;
				height: 24px;
				background: url('../../icons/details/2.two/arrow-down.svg');
				transform: translate(0%, -50%) rotate(180deg);
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.5s ease;
			}
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translate(0%, -50%) rotate(-180deg);
				right: 39px;
				width: 24px;
				height: 24px;
				background: url('../../icons/details/2.two/arrow-down.svg');
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.5s ease;
			}
			&__a {
				&::after {
					transform: translate(0%, -50%) rotate(360deg);
				}
				&::before {
					transform: translate(0%, -50%) rotate(-360deg);
				}
			}
		}
		&-btn {
			width: 300px;
			height: 80px;
			background: #121212;
			border: 2px solid #595959;
			border-radius: 51px;
			transition: all 0.5s ease;
			font-family: TTNorms;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 26px;
			text-align: center;
			text-transform: uppercase;
			color: #ffffff;
			position: relative;
			z-index: 5;
			&:hover {
				border-radius: 18px;
				transition: all 0.5s ease;
			}
			&__a {
				background: #ffffff;
				border-radius: 16px;
				border: 2px solid #ffffff;
				font-family: TTNorms;
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 26px;
				text-align: center;
				text-transform: uppercase;
				color: #0d0d0d;
			}
		}
		&-c {
			position: relative;
			z-index: 5;
			margin: 90px 0 0 0;
			width: 100%;
		}
		&-t {
			font-family: TTNorms;
			font-style: normal;
			font-weight: 700;
			font-size: 25px;
			line-height: 31px;
			color: #ffffff;
		}
		&-text {
			margin: 30px 0 0 0;
			& p {
				font-family: TTNorms;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 31px;
				width: 610px;
				color: #ffffff;
				&:nth-child(2) {
					margin: 24px 0 0 0;
				}
			}
		}
		&-b {
			// display: flex;
			// width: 100%;
			// align-items: flex-start;
			// justify-content: space-between;
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
			gap: 50px 100px;
			margin: 80px 0 0 0;
		}
		&-i {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 385px;
		}
		&-titles {
			font-family: TTNorms;
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 31px;
			color: #ffffff;
		}
		&-descriptor {
			font-family: TTNorms;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 31px;
			color: #ffffff;
			& > div {
				position: relative;
				padding: 0 0 0 20px;
				&::after {
					content: '';
					position: absolute;
					top: 15px;
					left: 0;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background: #fff;
				}
			}
		}
		&-bg {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 630px) {
	.slider__two-open {
		position: absolute;
		z-index: 5;
		bottom: 41px;
		padding: 9px 0 !important;
		right: 30px !important;
		width: 164px;
		height: unset !important;
	}
	.slider__two-title {
		position: absolute;
		bottom: 20%;
		left: 32px !important;
		font-family: TTNorms;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.3em;
		color: #fffef4;
		z-index: 10;
		text-transform: uppercase;
	}
	.slider__five-contact {
		right: auto !important;
		left: 32px !important;
	}
}

@media (max-width: 1800px) {
	.detailTwo__footer-w {
		width: 60%;
		max-width: 1440px;
		padding: 0 50px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media (max-width: 1415px) {
	.detailTwo__team-description {
		position: absolute;
		padding: 50px 0;
		left: -85%;
		top: 0px;
		width: 500px;
		opacity: 0;
		transition: all 0.5s ease;
	}
}

@media (max-width: 1400px) {
	.detailTwo__footer-w {
		width: 70%;
		max-width: 1440px;
		padding: 0 50px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media (max-width: 1315px) {
	.detailTwo__brend-l {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
		border: none;
	}
	.detailTwo__team-description {
		position: static;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		opacity: 1;
	}
	.detailTwo__team-items {
		background: transparent;
		width: 100%;
		height: 100%;
	}
	.detailTwo__team-parent {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		& canvas {
			width: 650px !important;
			height: 260px !important;
		}
	}
	.detailTwo__team-title {
		top: 110px;
		transform: translate(-50%, 0);
		font-size: 48px;
		line-height: 50px;
	}
	.detailTwo__team-description {
		width: 650px;
	}
	.detailTwo__portfolio-description {
		position: static;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		opacity: 1;
	}
	.detailTwo__portfolio-items {
		background: transparent;
		width: 100%;
		height: 100%;
	}
	.detailTwo__portfolio-parent {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		& canvas {
			width: 650px !important;
			height: 260px !important;
		}
	}
	.detailTwo__portfolio-title {
		top: 110px;
		transform: translate(-50%, 0);
		font-size: 48px;
		line-height: 50px;
	}
	.detailTwo__portfolio-description {
		width: 650px;
		margin: 30px 0;
		padding: 0;
	}
	.detailTwo__description-b {
		width: 100%;
		margin: 40px 0 0 0;
	}
	.detailTwo__description-g {
		position: static;
		margin: 60px auto 0 auto;
	}
	.detailTwo__description-d,
	.detailTwo__description-t,
	.detailTwo__description-b h3,
	.detailTwo__description-b p {
		text-align: center;
		width: 100%;
	}
}

@media (max-width: 1200px) {
	.detailTwo__footer-w {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 30px;
		justify-items: center;
	}
}

@media (max-width: 1150px) {
	.detailTwo__line .marquee {
		font-family: EkorsNonCommercial;
		font-style: normal;
		font-weight: bold;
		font-size: 400px;
		line-height: 450px;
		color: #f6f6f4;
		margin: 0 !important;
	}
	.detailTwo {
		width: 100%;
		background: #121212;
		position: relative;
		padding: 500px 0 0 0;
		// overflow-x: hidden;
	}
	.detailTwo__line {
		position: absolute !important;
		top: 70px;
		z-index: 3;
		overflow: hidden;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media (max-width: 900px) {
	.detailTwo__team-poster {
		display: flex;
		width: 100%;
		height: 150px;
		border-radius: 200px;
		overflow: hidden;
		& img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.detailTwo__team-parent {
		flex-direction: column;
	}
	.detailTwo__portfolio-poster {
		display: flex;
		width: 100%;
		height: 150px;
		border-radius: 200px;
		overflow: hidden;
		& img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.detailTwo__portfolio-parent {
		flex-direction: column;
	}
	.detailTwo__tabs-header {
		display: flex;
	}
	.detailTwo__tabs-btns {
		width: calc(100% - 120px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		gap: 0;
		padding: 25px;
		background: #121212;
		border: 2px solid #595959;
		border-radius: 51px;
		position: absolute;
		left: 50%;
		top: 180px;
		transform: translate(-50%, 0%);
		z-index: 15;
	}
	.detailTwo__tabs-btn {
		margin: 0 0 25px 0;
		width: 100%;
		&:last-child {
			margin: 0;
		}
	}
	.marquee {
		& img {
			height: 250px;
		}
	}
	.detailTwo__line {
		top: 120px;
	}
	.detailTwo {
		padding: 490px 0px 0px;
	}
	.detailTwo__description {
		padding: 30px 0 50px 0;
	}
	.detailTwo__description-t {
		font-style: normal;
		font-weight: 400;
		font-size: 40px;
		line-height: 77px;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__description-d {
		font-family: TTNorms;
		font-style: normal;
		font-weight: 700;
		font-size: 27px;
		line-height: 51px;
		text-align: center;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__description-g {
		width: 365px;
		height: 580px;
		top: auto;
		left: auto;
		right: auto;
	}
	.detailTwo__description-glish {
		width: 100%;
		height: 100%;
		& canvas {
			width: 365px !important;
			height: 580px !important;
		}
	}
	.detailTwo__decor {
		top: 130px;
	}
}

@media (max-width: 880px) {
	.detailTwo__footer-year {
		grid-area: 2 / 1;
	}
	.detailTwo__footer-w {
		width: 100%;
		gap: 0;
	}
	.detailTwo__footer-l {
		display: none;
	}
	.detailTwo__footer {
		width: 100%;
		padding: 44px 0 85px 0;
		background: #1a1a1a;
		margin: 150px 0 0 0;
		position: relative;
		z-index: 4;
	}
	.detailTwo__description {
		width: 100%;
	}
	.detailTwo__brend-t {
		width: 100%;
	}
}

@media (max-width: 730px) {
	.detailTwo__team-description {
		width: 100%;
	}
	.detailTwo__team-parent canvas {
		width: 100% !important;
	}
	.detailTwo__team-title {
		font-size: 38px;
		line-height: 40px;
		top: 120px;
	}
	.detailTwo__portfolio-description {
		width: 100%;
	}
	.detailTwo__portfolio-parent canvas {
		width: 100% !important;
	}
	.detailTwo__portfolio-title {
		font-size: 38px;
		line-height: 40px;
		top: 115px;
	}
}

@media (max-width: 700px) {
	.detailTwo__tabs-bg {
		display: none;
	}
	.detailTwo__tab {
		margin: 10px 0 0 0;
	}
	.detailTwo__description {
		padding: 30px 0 10px 0;
	}
	.detailTwo__team {
		padding: 30px 0 30px 0 !important;
	}
	.detailTwo__tabs-c {
		position: relative;
		z-index: 5;
		margin: 40px 0 0 0;
		width: 100%;
	}
	.detailTwo__team-t {
		width: 100%;
		text-align: center;
		font-style: normal;
		font-weight: 400;
		font-size: 40px;
		line-height: 77px;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__team-w {
		color: #fefefe;
		font-size: 30px !important;
		height: 80px !important;
		overflow: hidden;
		position: relative;
		width: 100vw;
	}
	.detailTwo__team-text {
		font-style: normal;
		font-weight: 700 !important;
		font-size: 30px !important;
		line-height: 40px !important;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__team-text {
		height: 42px !important;
	}
	.detailTwo__team-e {
		width: 15px !important;
		height: 15px !important;
	}
	.detailTwo__partner {
		padding: 30px 0 10px 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		position: relative;
		z-index: 5;
	}
	.detailTwo__partner-t {
		width: 100%;
		text-align: center;
	}
	.detailTwo__team-t {
		margin: 0 auto !important;
	}
}

@media (max-width: 650px) {
	.detailTwo__container {
		padding: 0 32px;
	}
	.detailTwo__tabs-text p {
		width: 100%;
	}
	.detailTwo__tabs-btns .detailTwo__tabs-text p {
		width: 100%;
	}
	.detailTwo__tabs-i {
		width: 100%;
	}
	.detailTwo__tabs-b {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0;
	}
	.detailTwo__tabs-i {
		margin: 0 0 55px 0;
		&:last-child {
			margin: 0;
		}
	}
	.detailTwo__tab {
		padding: 90px 20px 30px 20px;
	}
	.detailTwo__tabs-btns {
		width: calc(100% - 40px) !important;
	}
}

@media (max-width: 600px) {
	.detailTwo__brend-t {
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 32px;
		line-height: 45px;
		color: #f6f6f4;
	}
	.detailTwo__team-description p {
		font-family: TTNorms;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 38px;
		text-transform: uppercase;
		color: #ffffff;
	}
	.interface__close-d-two {
		right: 30px;
	}
}

@media (max-width: 500px) {
	.detailTwo__brend {
		margin: 125px 0 100px 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 3;
	}
	.detailTwo__tabs-btn {
		border-radius: 51px !important;
	}
	.detailTwo__footer {
		width: 100%;
		padding: 44px 0 85px 0;
		background: #1a1a1a;
		margin: 0 0 0 0;
		position: relative;
		z-index: 4;
	}
	.interface__close-d-two {
		position: fixed;
		top: 29px;
		right: 30px !important;
	}
	.detailTwo__team-description p {
		font-family: TTNorms;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 37px;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__description p {
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 27px;
		line-height: 25px;
		color: #f6f6f4;
		width: 100%;
	}
	.detailTwo__description span {
		margin: 27px 0 0 0;
		font-family: TTNorms;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 30px;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__line .marquee {
		font-family: EkorsNonCommercial;
		font-style: normal;
		font-weight: bold;
		font-size: 180px;
		line-height: 310px;
		color: #f6f6f4;
		margin: 0 !important;
	}
	.detailTwo {
		width: 100%;
		background: #121212;
		position: relative;
		padding: 310px 0 0 0;
		// overflow-x: hidden;
	}
	.detailTwo__decor {
		position: absolute;
		width: 185px;
		// display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		gap: 55px;
		z-index: 1;
		left: 50%;
		transform: translate(-50%, 0%);
		top: -60px;
	}
	.interface__close-d-two p {
		display: none;
	}
	.detailTwo__portfolio-parent canvas {
		height: 150px !important;
	}
	.detailTwo__team-parent canvas {
		height: 150px !important;
	}
	.detailTwo__portfolio-title {
		font-size: 28px;
		line-height: 40px;
		top: 63px;
		&:hover {
			font-size: 18px;
		}
	}
	.detailTwo__team-title {
		font-size: 28px;
		line-height: 40px;
		top: 63px;
		&:hover {
			font-size: 18px;
		}
	}
	.detailTwo__team-description {
		padding: 35px 0;
	}
	.detailTwo__team-description p {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		align-content: center;
		justify-items: center;
	}
	.detailTwo__team {
		margin: 90px 0 0 0;
	}
	.detailTwo__team-items {
		margin: 0 0 20px 0;
	}
	.detailTwo__brend {
		margin: 70px 0 20px 0;
	}
	.marquee img {
		height: 185px;
	}
	.detailTwo {
		padding: 370px 0px 0px !important;
	}
	.detailTwo__description-glish canvas {
		width: 100% !important;
		height: 580px !important;
	}
	.detailTwo__description-g {
		width: 100%;
	}
	.detailTwo__description p {
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 27px;
		line-height: 40px;
		color: #f6f6f4;
		width: 100%;
	}
	.detailTwo__partner-t {
		font-style: normal;
		font-weight: 400;
		font-size: 40px;
		line-height: 77px;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		color: #ffffff;
	}
	.detailTwo__team-t {
		font-weight: 400 !important;
		font-size: 40px !important;
		line-height: 77px !important;
	}
	.detailTwo__decor {
		width: 76px;
		gap: 18px;
	}
	.detailTwo__decor-i:nth-child(odd) {
		height: 76px;
	}
	.detailTwo__decor-i:nth-child(even) {
		height: 190px;
		border-radius: 90px;
		background: #212121;
	}
	.detailTwo__decor {
		top: 270px;
	}
}

@keyframes marquee {
	0% {
		transform: translateX(-50%);
	}
	50% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

@keyframes marquee-r {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(0);
	}
}

.detailTwo {
	transition: height 0.5 ease;
	&__team {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 250px 0 30px 0;
		position: relative;
		z-index: 5;
		&-t {
			width: 100%;
			max-width: 1440px;
			padding: 0 50px;
			margin: 0 auto 30px auto;
			font-family: Ekors_Cyrilic_fmch_art_Regular;
			font-style: normal;
			font-weight: 400;
			font-size: 60px;
			line-height: 77px;
			letter-spacing: 0.09em;
			text-transform: uppercase;
			color: #ffffff;
		}
		&-e {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background: #ffffff;
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translate(0%, -50%);
		}
		&-w {
			color: #fefefe;
			font-size: 30px;
			height: 120px;
			overflow: hidden;
			position: relative;
			width: 100vw;
			&__a {
				& .detailTwo__team-track {
					animation-play-state: running !important;
				}
				& .detailTwo__team-animation {
					animation-play-state: running !important;
				}
			}
		}
		&-track {
			overflow: hidden;
			position: absolute;
			/* transition: background-color 300ms ease, color 300ms ease; */
			white-space: nowrap;
			transition: all 0.5s ease;
			margin: 45px 0 0 0;
			align-items: center;
			display: inline-flex;
			will-change: transform;
			animation: marquee 55000ms linear infinite;
			animation-play-state: paused !important;
		}
		// &-animation {
		// 	animation-play-state: running !important;
		// }
		&-r {
			animation: marquee-r 55000ms linear infinite !important;
			animation-play-state: paused !important;
		}
		&-text {
			// align-items: center;
			// display: inline-flex;
			// will-change: transform;
			// animation: marquee 35000ms linear infinite;
			// animation-play-state: paused !important;
			font-family: TTNorms;
			font-style: normal;
			font-weight: 700;
			font-size: 40px;
			line-height: 70px;
			text-transform: uppercase;
			color: #ffffff;
			height: 70px;
			background: transparent;
			padding: 0 63px 0 0;
			position: relative;
			transition: all 0.5s ease;
			&:hover {
				-webkit-text-stroke-width: 2px;
				-webkit-text-stroke-color: #fff;
				color: transparent;
				transition: all 0.5s ease;
			}
		}
	}
}

//! Custom select in Tabs

.cSelect__containers {
	width: 10.5em;
	margin: 0 auto;
	position: relative;
	z-index: 15;
	& button {
		color: #fff;
	}
}

.cSelect__header {
	margin-bottom: 0.8em;
	padding: 0.4em 2em 0.4em 1em;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
	font-weight: 500;
	font-size: 1.3rem;
	color: #3faffa;
	background: #ffffff;
}

.cSelect__lists {
}

.cSelect__list {
	padding: 0;
	margin: 0;
	padding-left: 1em;
	background: #ffffff;
	border: 2px solid #e5e5e5;
	box-sizing: border-box;
	color: #3faffa;
	font-size: 1.3rem;
	font-weight: 500;
	&:first-child {
		padding-top: 0.8em;
	}
}

.cSelect__items {
	color: #fff;
}
