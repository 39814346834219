.custom-class {
	width: 100%;
	height: 380px;
}
.pagination-bottom {
	font-size: 0px;
}

.pagination-top {
	position: absolute;
	top: 10px;
	right: 10px;
	margin: 0;
}

.pagination-top .swiper-pagination-bullet {
	width: auto;
	border-radius: 0px;
	height: auto;
	background-color: #fff;
	padding: 5px;
	color: #000;
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
}

.pagination-top .swiper-pagination-bullet-active {
	color: #fff;
	background: #007aff;
}

.slider__one {
	width: 100%;
	// height: 100vh;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	&-title {
		position: absolute;
		bottom: 23%;
		left: 145px;
		transform: none;
		text-align: left;
		z-index: 10;
		transition: all 0.5s ease;
		& p {
			font-family: TTNorms;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			letter-spacing: 0.3em;
			color: #ffffff;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}
	}
	&-types {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translate(-50%, 0);
		// opacity: 1 !important;
		text-align: left;
		z-index: 10;
		& h3 {
			font-family: NextArt;
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 30px;
			letter-spacing: 0.3em;
			color: #fffef4;
		}
	}
	& .swiper {
		width: 100%;
		// height: 100vh;
		height: 100%;
		position: relative;
	}
	& .swiper-pagination {
		position: absolute;
		top: auto !important;
		right: auto !important;
		bottom: 100px !important;
		width: 100% !important;
		z-index: 5 !important;
		left: 50% !important;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translate(-50%, 0%) !important;
	}
	& .swiper-pagination-bullet {
		&:nth-child(odd) {
			width: 8px !important;
			height: 8px !important;
			border: 1px solid #c4c4c4 !important;
			border-radius: 50% !important;
		}
		&:nth-child(even) {
			width: 41px !important;
			height: 8px !important;
			border: 1px solid #c4c4c4 !important;
			border-radius: 9px !important;
		}
	}
	& .swiper-pagination-bullet-active {
		background: #c4c4c4 !important;
	}
}

@media (max-width: 630px) {
	.slider__one-title {
		left: 32px;
	}
	.slider__one-open {
		right: 32px !important;
		width: 143px;
	}
	.slider__one .swiper-pagination {
		position: absolute;
		top: auto !important;
		right: auto !important;
		bottom: 100px !important;
		width: 150px !important;
		z-index: 5 !important;
		left: 32px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: none !important;
	}
}

@media (max-width: 550px) and (max-height: 650px) {
	.slider__one-title {
		bottom: 23%;
	}
}

@media (max-width: 550px) and (max-height: 550px) {
	.slider__one-title {
		bottom: 25%;
	}
}

@media (max-width: 550px) and (max-height: 500px) {
	.slider__one-title {
		bottom: 28%;
	}
}
