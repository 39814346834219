.details {
	&__footer {
		width: 100%;
		padding: 44px 0;
		background: #1a1a1a;
		margin: 150px 0 0 0;
		z-index: 12;
		&-w {
			width: 100%;
			max-width: 1440px;
			padding: 0 50px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		& .interface__link {
			position: static;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			margin: 0 0 0 0;
		}
		&-year {
			font-family: TTNorms;
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 26px;
			color: #ffffff;
		}
		&-contact {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 21px;
			&-i {
				font-family: TTNorms;
				font-style: normal;
				font-weight: normal;
				font-size: 13px;
				line-height: 26px;
				color: #ffffff;
				// cursor: pointer;
				cursor: var(--cursor);
			}
		}
		&-info {
			font-family: TTNorms;
			font-style: normal;
			// cursor: pointer;
			cursor: var(--cursor);
			font-weight: normal;
			font-size: 13px;
			line-height: 26px;
			color: #ffffff;
		}
		&-m {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10000000000001;
			background: #000;
			width: 100%;
			height: 100%;
			transition: all 0.5s ease;
			&__wrapper {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				width: 100%;
				overflow: auto;
				height: 100%;
				position: relative;
			}
			&__containers {
				position: relative;
				max-width: 1440px;
				margin: 0 auto;
				padding: 0 50px;
			}
			&__close {
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				// cursor: pointer;
				cursor: var(--cursor);
			}
			&__nav {
				margin: 30px 0 0 0;
				display: flex;
				width: 100%;
				align-items: center;
				flex-direction: row;
				justify-content: flex-end;
				padding: 0 50px;
				position: fixed;
				top: 0;
				z-index: 5;
				left: 0;
			}
			&__text {
				display: flex;
				width: 90%;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding: 0 0 50px 0;
				& h3 {
					margin: 90px 0 0 0;
					font-family: TTNorms;
					font-style: normal;
					font-weight: 500;
					font-size: 30px;
					line-height: 30px;
					color: #f6f6f4;
				}
				& p {
					margin: 35px 0 0 0;
					font-family: TTNorms;
					font-style: normal;
					font-weight: 400;
					font-size: 19px;
					line-height: 25px;
					color: #ffffff;
				}
			}
			&__title {
				margin: 90px 0 0 0;
				font-family: TTNorms;
				font-style: normal;
				font-weight: 500;
				font-size: 35px;
				line-height: 35px;
				color: #f6f6f4;
			}
		}
	}
}

@media (max-width: 1600px) {
	.details__footer-w {
		padding: 0 130px 0 50px;
	}
}

@media (max-width: 1200px) {
	.details__footer-w {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	.details__footer .interface__link {
		margin: 0 0 0 0;
	}
	.details__footer-contact {
		margin: 25px 0 0 0;
	}
	.details__footer-year {
		margin: 20px 0 0 0;
	}
	.details__footer-info {
		margin: 20px 0 0 0;
	}
}

@media (max-width: 700px) {
	.details__footer-w {
		padding: 0 20px;
	}
	.details__footer-contact-i {
		font-size: 11px;
	}
	.details__footer-info {
		font-size: 10px;
	}
	.details__footer-year {
		font-size: 10px;
	}
	.details__footer .interface__link {
		margin: 0 0 0 0;
	}
	.details__footer-contact {
		margin: 20px 0 0 0;
		gap: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	.details__footer-year {
		margin: 15px 0 0 0;
	}
}

@media (max-width: 650px) {
	.details__footer-m__nav {
		padding: 0 30px;
	}
	.details__footer-m__containers {
		padding: 0 30px;
	}
	.details__footer-m__text {
		width: 94%;
	}
}

@media (max-width: 500px) {
	.details__footer {
		margin: 75px 0 0 0;
	}
	.details__footer-m__title {
		font-size: 28px;
	}
	.details__footer-m__text h3 {
		margin: 90px 0 0 0;
		font-family: TTNorms;
		font-style: normal;
		font-weight: 500;
		font-size: 23px;
		line-height: 30px;
		color: #f6f6f4;
	}
	.details__footer-m__text p {
		margin: 35px 0 0 0;
		font-family: TTNorms;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 25px;
		color: #ffffff;
	}
}
