.profile {
	width: 100%;
	position: relative;
	background: #121212;
	&__container {
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 60px;
		position: relative;
		z-index: 5;
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		background: #121212;
	}
	&__header {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
	&__decor {
		position: absolute;
		width: 185px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		gap: 55px;
		z-index: 1;
		left: 50%;
		transform: translate(-50%, 0%);
		top: 950px;
		&-i {
			width: 100%;
			&:nth-child(odd) {
				height: 185px;
				border-radius: 50%;
				background: #323232;
			}
			&:nth-child(even) {
				height: 460px;
				border-radius: 90px;
				background: #212121;
			}
		}
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(630px, 1fr));
		width: 100%;
		gap: 38px 10px;
		justify-items: center;
		position: relative;
		z-index: 3;
		&-i {
			width: 630px;
			height: 280px;
			overflow: hidden;
			position: relative;
			&:nth-child(1) {
				grid-area: 1/1;
			}
			&:nth-child(2) {
				grid-area: 2/1;
			}
			&:nth-child(3) {
				grid-area: 2/2;
			}
			&:nth-child(4) {
				grid-area: 3/1;
			}
			&:nth-child(5) {
				grid-area: 3/2;
			}
			&:nth-child(6) {
				grid-area: 4/1;
			}
			&:nth-child(7) {
				grid-area: 4/2;
			}
			&:nth-child(8) {
				grid-area: 5/1;
			}
		}
		&-d {
			position: absolute;
			bottom: 20px;
			left: 32px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 1px;
			& h3 {
				font-family: TTNorms;
				font-style: normal;
				font-weight: normal;
				font-size: 11px;
				line-height: 27px;
				letter-spacing: 0.3em;
				color: #acacac;
				text-transform: uppercase;
			}
			& p {
				font-family: TTNorms;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 27px;
				letter-spacing: 0.3em;
				color: #fffef4;
				text-transform: uppercase;
			}
		}
	}
	&__loading {
		display: block;
		&-start {
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			background: #fff;
			z-index: 100000000;
		}
		&-state {
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			background: #000;
			z-index: 1000000001;
		}
	}
	&__info {
		width: 100%;
		height: 100%;
		position: relative;
		margin: 155px 0 0 0;
		padding: 0 0 210px 0;
		overflow-y: hidden;
		&-title {
			font-family: TTNorms;
			font-style: normal;
			font-weight: normal;
			font-size: 44px;
			line-height: 30px;
			letter-spacing: 0.3em;
			color: #fffef4;
			margin: 0 0 90px 120px;
		}
		&-wrapper {
			display: flex;
			position: relative;
			z-index: 5;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			gap: 120px;
			padding: 0 120px;
		}
		&-photo {
			position: relative;
			z-index: 7;
			width: 433px;
			height: 560px;
			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-description {
			margin: 35px 0 0 0;
			position: relative;
			z-index: 7;
			width: 540px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			&-i {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 20px;
				& h3 {
					font-family: TTNorms;
					font-style: normal;
					font-weight: normal;
					font-size: 13px;
					line-height: 26px;
					width: 120px;
					color: #b8b8b8;
				}
				& p {
					width: 405px;
					font-family: TTNorms;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 26px;
					color: #ffffff;
				}
			}
			&-l {
				width: 365px;
				height: 1px;
				border-top: 1px solid #343434;
				margin: 30px 0;
			}
		}
		&-decor {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 100px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 4;
			&-i {
				height: 406px;
				background: rgba(41, 41, 41, 0.5);
				&:nth-child(odd) {
					width: 406px;
					border-radius: 50%;
				}
				&:nth-child(even) {
					width: 806px;
					border-radius: 250px;
				}
			}
		}
	}
	&__bg {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		& img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
